import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useMediaQuery } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function FAQSection() {
    const isMobile = useMediaQuery('(max-width:1050px)')

    return (
        <>
            <Box sx={{
                margin: isMobile ? '1.2rem .8rem' : '0 10vw 4rem 10vw',
                borderRadius: '20px',
                background: '#f4f4f4',
                padding: isMobile ? '2rem 1rem' : '2rem 6vw',
                fontFamily: 'catamaran'
            }}>
                <Typography
                    variant={isMobile ? 'h4' : 'h3'}
                    component="h3"
                    sx={{
                        color: 'primary.main',
                        fontFamily: 'inherit',
                        fontWeight: 900,
                        textAlign: 'center',
                        m: '0 0 1.6rem 0',
                    }}>
                    FAQs
                </Typography>

                <Accordion sx={{
                    p: '.2rem'
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        What is Vibio?
                    </AccordionSummary>
                    <AccordionDetails>
                        Vibio is a <b>video integrated link-in-bio tool</b>. The video you upload plays automatically in a loop.  We encourage you
                        to get creative with the videos you upload. It could be a personal introduction, showcase of your work or business or just something fun!
                        You can add your social accounts, calendar, website or anything to your page! Since Vibio is an "Early  Release"
                        you may feel like some feature you wish might not be present. <b>We'd love to hear your feedback</b> to improve
                        Vibio the way you want it to be!
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{
                    p: '.2rem'
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        How does it work?
                    </AccordionSummary>
                    <AccordionDetails>
                        Vibio is a website that you can build your bio. When your visitors come from external sources they will see your Vibio page loading in a browser window.
                        They can easily navigate to your social accounts or any external web URLs you have set up in your page.
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{
                    p: '.2rem'
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        What does "Early Release" mean?
                    </AccordionSummary>
                    <AccordionDetails>
                        <b>Early Release is the first publicly available <i>free</i> version of Vibio.</b> This means you will be the first to experience Vibio and what  it has to offer.
                        We'd love to  hear your input if you ever notice a missing feature during the "Early Release" version.
                        You will pave the way for others yet  to adopt Vibio. <b>Your feedback will help us improve</b> Vibio the way our users want it to be.
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{
                    p: '.2rem'
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        What is next for Vibio?
                    </AccordionSummary>
                    <AccordionDetails>
                        We're working effortlessly on improving Vibio with great features asked by our users just like you!
                        Customizations, analytics, higher quality & longer video allowance, and more are coming soon. You can help us shape these features with your feedback.
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{
                    p: '.2rem'
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        Data & Privacy
                    </AccordionSummary>
                    <AccordionDetails>
                        Vibio <b>does NOT share or sell</b> your data to any 3rd parties.
                    </AccordionDetails>
                </Accordion>

            </Box>
        </>
    )
}