import { Box, Paper, Typography, useMediaQuery } from "@mui/material"
import ProfileSettings from "./ProfileSettings"
import SubscriptionSettings from "./SubscriptionSettings"


export default function SettingsTab() {
    const isMobile = useMediaQuery('(max-width:1050px)')

    return (
        <>
            <Box sx={{
                m: 0,
                p: isMobile ? 0 : '0 2rem',
                gap: '1rem',
            }}>
                <ProfileSettings />
                <SubscriptionSettings />
            </Box>
        </>
    )
}
