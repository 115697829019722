import React, { createContext, useContext, useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

const SecurityContext = createContext()

export const useAuth = () => useContext(SecurityContext)

const URL = process.env.REACT_APP_BACKEND_URL

export const SecurityContextProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token') || null)
    const [userData, setUserData] = useState(null)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [loading, setLoading] = useState(true)

    const checkTokenExpiration = useCallback(() => {
        if (token) {
            try {
                const decodedToken = jwtDecode(token)
                const currentTime = Date.now() / 1000

                if (decodedToken.exp < currentTime) {
                    console.log('Token has expired')
                    logout()
                }
            } catch (error) {
                console.error('Error decoding token:', error)
                logout()
            }
        }
    }, [token])

    const fetchUserData = async () => {
        if (!token) {
            setIsAuthenticated(false)
            setLoading(false)
            return
        }
        try {
            const email = jwtDecode(token).email
            const response = await axios.get(`${URL}/api/users/@${email}`)
            if (response.status === 200) {
                setUserData(response.data)
                setIsAuthenticated(true)
            }
        } catch (error) {
            console.error('Failed to fetch user data', error)
            logout()
        } finally {
            setLoading(false)
        }
    }

    const login = ({ token, userData }) => {
        localStorage.setItem('token', token)
        setToken(token)
        setUserData(userData)
        setIsAuthenticated(true)
        setLoading(false)
    }

    const logout = () => {
        localStorage.removeItem('token')
        setToken(null)
        setUserData(null)
        setIsAuthenticated(false)
    }

    useEffect(() => {
        fetchUserData()

        const intervalId = setInterval(() => {
            checkTokenExpiration()
        }, 10 * 60 * 1000) // 10 minutes in milliseconds

        // Clean up interval on component unmount
        return () => clearInterval(intervalId)
    }, [token, checkTokenExpiration])

    return (
        <SecurityContext.Provider value={{
            userData,
            login,
            logout,
            isAuthenticated,
            loading,
            token,
            checkTokenExpiration
        }}>
            {children}
        </SecurityContext.Provider>
    )
}

export default SecurityContextProvider
