import { Box, CardMedia, Typography } from "@mui/material"
import React from "react"
import GetStartedVibioForm from "./GetStartedVibioForm"
import { useMediaQuery } from '@mui/material'


export default function HookSection() {
    const isMobile = useMediaQuery('(max-width:1050px)')

    return (
        <>
            <Box sx={{
                backgroundColor: 'primary.bg',
                color: 'primary.main',
                m: isMobile ? '1rem 0' : '.5rem 6vw',
                p: isMobile ? '1rem 0' : '4rem 6rem',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flexWrap: 'wrap',
                minHeight: 660,
            }}>

                {/* text section */}
                <Box sx={{
                    flex: 4,
                    textAlign: 'left',
                    m: 0,
                    p: '0 1.2rem',
                    fontWeight: 600,
                    fontFamily: 'Catamaran',
                }}>

                    {/* banner */}
                    <Box sx={{
                        m: 0,
                        p: '0 .2rem',
                        '& > *': {
                            mb: '1.8rem',
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: isMobile ? '' : 'center',
                        height: isMobile ? '42vh' : '74vh',
                    }}>
                        <Typography
                            variant={isMobile ? 'h3' : 'h2'}
                            component="h2" sx={{
                                fontFamily: 'inherit',
                                fontWeight: 800,
                                mb: 2
                            }}>
                            Your video integrated link-in-bio tool!
                        </Typography>

                        <Typography
                            variant="h6"
                            component="h4"
                            sx={{
                                fontFamily: 'inherit',
                                fontWeight: 550,
                                mb: 4,
                                maxWidth: '90%',
                                color: 'text.darkGray'
                            }}>
                            Turn your link-in-bio page into a video, get creative
                            to best express yourself and what you have to offer!
                            List all your links and socials.
                        </Typography>

                        <GetStartedVibioForm />
                    </Box>


                </Box>

                {/* media section */}
                <Box sx={{
                    flex: 3,
                    m: isMobile ? '13.6vh 0 0 0' : 0,
                    p: '1rem 0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    height: '80vh',
                }}>
                    <CardMedia
                        component="img"
                        src="/demo-img.png"
                        title="Product showcase img-1"
                        sx={{
                            height: '92%',
                            width: '86%',
                            objectFit: 'contain'
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}
