import React, { useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { MobileView } from './MobileView'
import { DesktopView } from './DesktopView'
import { getUserByUsername } from "../../../service/UserDataService"
import UserNotFoundView from '../error/UserNotFoundView'
import PreviewBanner from './preview/PreviewBanner'
import BrowserRedirect from '../../util/BrowserRedirect'

export default function VideoPage() {
    const { username } = useParams()
    const [searchParams] = useSearchParams()
    const isPreview = searchParams.get('preview') === 'true'
    const [user, setUser] = useState(null)
    const [videoSrc, setVideoSrc] = useState(null)
    const [linkButtons, setLinkButtons] = useState([])
    const [socials, setSocials] = useState([])
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        const fetchUser = async () => {
            setIsLoading(true)
            try {
                const fetchedUser = await getUserByUsername(username)
                if (fetchedUser && fetchedUser.videoPages && fetchedUser.videoPages.length > 0) {
                    const vp = fetchedUser.videoPages[0]
                    setUser(fetchedUser)
                    setSocials(vp.socials)
                    setLinkButtons(vp.links)
                    setVideoSrc(vp.videoSrc)
                } else {
                    console.error("User or video page data is missing")
                }
            } catch (error) {
                console.error("Error fetching user data: ", error)
            } finally {
                setIsLoading(false)
            }
        }
        fetchUser()
    }, [username])

    const isMobile = windowWidth < 780

    const content = isLoading ? (
        <div>Loading...</div>
    ) : !user ? (
        <UserNotFoundView />
    ) : (
        <div style={{ padding: 0, margin: 0 }}>
            {isMobile ? (
                <>
                    {isPreview && <PreviewBanner />}
                    <MobileView
                        videoSrc={videoSrc}
                        socials={socials}
                        linkButtons={linkButtons}
                        userData={user}
                    />
                </>
            ) : (
                <DesktopView
                    videoSrc={videoSrc}
                    socials={socials}
                    linkButtons={linkButtons}
                    userData={user}
                />
            )}
        </div>
    )

    return <BrowserRedirect>{content}</BrowserRedirect>
}
