import axios from 'axios'
import { SocialLink } from '../model/SocialLink'


const backendUrl = process.env.REACT_APP_BACKEND_URL
const DOMAIN = `${backendUrl}/api/socials`

// GET ALL by VideoPage ID
export async function getSocialLinks(pageId) {
    const token = localStorage.getItem('token')
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.get(
            `${DOMAIN}/vp/${pageId}`,
            { headers: headers },
        )
        if (!response.data) return []
        return response.data.map(social => new SocialLink(social.id, social.url, social.icon, social.iconCode, social.videoPageId))
    } catch (error) {
        if (error.response.status === 401) {
            console.error("User is not logged in or JWT has expired")
            window.location.replace("/login")
            return
        }
        console.error(`Error occured while getting new social link: ${error}`)
        return []
    }
}

// ADD new SocialLink
export async function addSocialLink(socialLink) {
    const token = localStorage.getItem('token')

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    try {
        const response = await axios.post(
            `${DOMAIN}/${socialLink.videoPageId}`,
            socialLink,
            {
                headers: headers,
            }
        )
        console.log(response)
        if (response.status === 201) {
            return true
        }
    } catch (error) {
        console.error(`Error occured while adding new social link: ${error}`)
        return false
    }
}

// UPDATE Social Link
export async function updateSocialLink(socialLink) {
    const token = localStorage.getItem('token')

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.put(
            `${DOMAIN}/${socialLink.id}/${socialLink.videoPageId}`,
            socialLink,
            { headers: headers }
        )
        if (response.status === 204) {
            return true
        }
    } catch (error) {
        console.error(`Error occured while updating social link: ${error}`)
        return false
    }
}
