import { Button, Typography, useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"

export function BioSettings({ onUpdate, bioText }) {
    const [newBio, setNewBio] = useState(bioText || "")
    const [isDisabled, setIsDisabled] = useState(true)
    const isMobile = useMediaQuery('(max-width:1050px)')


    useEffect(() => {
        if (newBio !== bioText) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [newBio, bioText])

    const handleBioChange = (event) => setNewBio(event.target.value)

    const handleBioSave = () => {
        onUpdate(newBio)
        setIsDisabled(true)
    }


    return (
        <>
            <Typography
                variant="body1"
                component="p"
                sx={{
                    fontWeight: 500,
                    fontFamily: "inherit",
                    m: .5,
                }}
            >
                Bio
            </Typography>
            <form style={{
                display: 'flex',
                flexDirection: 'column',
                width: isMobile ? '100%' : '60%',
            }}>
                <textarea
                    value={newBio}
                    onChange={handleBioChange}
                    placeholder="express yourself!"
                    maxLength={100}
                    style={{
                        resize: 'none',
                        border: 'none',
                        borderRadius: '10px',
                        fontFamily: 'inherit',
                        background: '#dedfde',
                        padding: '.4rem',
                        maxHeight: '400px',
                    }}
                />
                <Button
                    variant='contained'
                    type='button'
                    disabled={isDisabled}
                    onClick={handleBioSave}
                    sx={{
                        textTransform: 'none',
                        alignSelf: 'end',
                        m: '.32rem 0',
                        cursor: isDisabled ? 'none' : 'auto',
                        '&:disabled': {
                            cursor: 'not-allowed',
                        },
                    }}
                >
                    Save
                </Button>
            </form>

        </>
    )
}
