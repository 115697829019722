import { Box, Button, Divider, Typography } from "@mui/material";

export default function UserNotFoundView() {

    return (
        <>
            <Box sx={{
                // border: '1px orange solid',
                color: 'text.regular',
                m: 0,
                p: 2,
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontFamily: 'Catamaran',
                backgroundColor: 'primary.main',
                alignItems: 'center',
            }}>
                <Typography
                    variant="h2"
                    component="h2"
                    sx={{
                        fontWeight: 900,
                        fontFamily: 'inherit',
                        textAlign: 'center',
                    }}
                >
                    Oh, no!
                </Typography>

                <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                        fontWeight: 500,
                        fontFamily: 'inherit',
                        textAlign: 'center',
                    }}
                >
                    404 - User page not found
                    <br />
                    This page could be yours!
                </Typography>
                <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    sx={{
                        m: '1.4rem',
                        p: '.8rem 1.2rem',
                        fontFamily: 'inherit',
                        color: 'primary.main',
                        backgroundColor: 'text.regular',
                        textTransform: 'none',
                        fontWeight: 600,
                        ":hover": {
                            backgroundColor: '#d6d6d6',
                            color: 'primary.main',
                        }
                    }}
                    onClick={() => window.open("https://vibio.co/login")}
                >
                    <span
                        style={{
                            fontSize: '1rem',
                            textDecoration: 'none',
                            color: 'inherit'
                        }}>
                        Try Vibio
                    </span>
                </Button>
                <Typography
                    variant="h4"
                    component="h5"
                    sx={{
                        fontWeight: 800,
                        fontFamily: 'inherit',
                        textAlign: 'center',
                        marginTop: '4rem',
                    }}
                >
                    Vibio
                </Typography>
            </Box>
        </>

    )
}
