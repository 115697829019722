import React, { createContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { LinkButton } from '../model/LinkButton'
import { addLinkButton, deleteLinkButton, getLinkButtons, updateLinkButton } from '../service/LinkButtonDataService'
import { getSocialLinks } from "../service/SocialLinkDataService"


export const LinksContext = createContext()


export const LinksProvider = ({ user, children }) => {
    const [openLinkModal, setOpenLinkModal] = useState(false)
    const [openIconModal, setOpenIconModal] = useState(false)
    const [linkButtons, setLinkButtons] = useState([])
    const [socialLinks, setSocialLinks] = useState([])


    useEffect(() => {
        const fetchLinkButtons = async () => {
            try {
                const username = user.username
                const linkButtonsData = await getLinkButtons(username)
                setLinkButtons(linkButtonsData)
            } catch (error) {
                console.error("Error fetching link buttons:", error)
            }
        }

        fetchLinkButtons()
    }, [user.username])

    useEffect(() => {
        const fetchSocialLinks = async () => {
            try {
                const pageId = user.videoPages[0].pageId
                const socialLinksData = await getSocialLinks(pageId)
                setSocialLinks(socialLinksData)
            } catch (error) {
                console.error("Error fetching social links:", error)
            }
        }

        fetchSocialLinks()
    }, [user.videoPages])


    const handleAddLink = async (newLink) => {
        const newLinkButton = new LinkButton("", linkButtons.length + 1, newLink.title, newLink.url, "")
        try {
            const pageId = user.videoPages[0].pageId
            newLinkButton.uuid = await addLinkButton(newLinkButton, pageId)
            setLinkButtons((prevButtons) => [...prevButtons, newLinkButton])
        } catch (error) {
            console.error("Error adding link button:", error)
        }
    }

    const handleReorderLinks = async (newOrder) => {
        setLinkButtons(newOrder)

        // Update the order property of each link button
        const updatedButtons = newOrder.map((button, index) => ({
            ...button,
            id: index + 1
        }))

        // Update each link button in the database
        const pageId = user.videoPages[0].pageId
        for (let button of updatedButtons) {
            try {
                await updateLinkButton(button, pageId)
            } catch (error) {
                console.error("Error updating link button order:", error)
            }
        }
    }

    const handleUpdateLink = async (updatedLink) => {
        try {
            const pageId = user.videoPages[0].pageId
            const isUpdateOK = await updateLinkButton(updatedLink, pageId)
            if (!isUpdateOK) {
                Swal.fire({ title: "Update failed", text: "Couldn't update your link. Please try again later.", icon: "error" })
            } else {
                Swal.fire({ title: "Updated!", text: "Your link has been updated successfully", icon: "success" })
            }
            setLinkButtons((linkButtons) =>
                linkButtons.map((link) => (link.id === updatedLink.id ? updatedLink : link))
            )
        } catch (error) {
            console.error("Error updating link button:", error)
        }
    }

    const handleDeleteLink = async (uuid) => {
        const isDeleteOK = await deleteLinkButton(uuid)
        if (isDeleteOK) {
            setLinkButtons(linkButtons.filter(link => link.uuid !== uuid))
        } else {
            Swal.fire({
                title: "Delete failed",
                text: "Couldn't delete your link. Please try again later.",
                icon: "error"
            })
        }
    }

    const handleSocialIconChange = (newSocialIcon) => setSocialLinks(newSocialIcon)


    return (
        <LinksContext.Provider value={{
            linkButtons,
            socialLinks,
            openLinkModal,
            setOpenLinkModal,
            openIconModal,
            setOpenIconModal,
            handleAddLink,
            handleReorderLinks,
            handleUpdateLink,
            handleDeleteLink,
            handleSocialIconChange,
        }}>
            {children}
        </LinksContext.Provider>
    )
}
