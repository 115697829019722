import React, { useState } from 'react'
import { Box, Button, Typography, Modal, TextField, useMediaQuery } from '@mui/material'

export function UpdateLinkButtonModal({ open, onClose, onUpdateLink, title, url }) {
    const [newTitle, setNewTitle] = useState(title)
    const [newUrl, setNewUrl] = useState(url)
    const isMobile = useMediaQuery('(max-width:1050px)')


    const handleUpdateLink = () => {
        if (title && url) {
            onUpdateLink({ newTitle, newUrl })
            setNewTitle('')
            setNewUrl('')
            onClose()
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? 300 : 380,
                bgcolor: 'background.paper',
                borderRadius: '10px',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Update link
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label="Title"
                    name="title"
                    autoComplete="title"
                    autoFocus
                    value={newTitle}
                    placeholder={title}
                    onChange={(e) => setNewTitle(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="url"
                    label="URL"
                    name="url"
                    autoComplete="url"
                    value={newUrl}
                    placeholder={url}
                    onChange={(e) => setNewUrl(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdateLink}
                    sx={{ mt: 2 }}
                >
                    Update
                </Button>
            </Box>
        </Modal>
    )
}
