import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'


export default function PreviewBanner() {
    const navigate = useNavigate()

    return (
        <>
            <div style={{
                padding: '0 1rem',
                background: '#ffea00',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <Button
                    variant="contained"
                    onClick={() => navigate("/studio")}
                    startIcon={<ArrowBackIcon sx={{ fontSize: 24 }} />}
                    sx={{
                        m: '.2rem 0',
                        p: '.5rem',
                        textTransform: "none",
                        backgroundColor: '#0f0f0f',
                        color: 'primary.bg',
                        borderRadius: '10px',
                        boxShadow: 'none',
                    }}
                >
                    Go Back
                </Button>
                <h3 style={{
                    margin: '0 1.2rem',
                    padding: '.6rem 0',
                    justifySelf: 'center',
                    alignSelf: 'center',
                    flex: 2,
                }}>
                    THIS IS A PREVIEW
                </h3>
            </div>
        </>
    )
}
