import { Box, CardMedia, Typography } from "@mui/material"
import React from "react"
import { useMediaQuery } from '@mui/material'


export default function VibioShowCaseSection() {
    const isMobile = useMediaQuery('(max-width:1050px)')

    return (
        <>
            <div style={{
                background: '#f4f4f4',
                margin: isMobile ? '.8rem' : '4rem 10vw',
                borderRadius: '20px',
                padding: isMobile ? '2rem 1rem' : '1.6rem 0',
            }}>
                <Typography
                    variant={isMobile ? 'h4' : 'h3'}
                    component="h4"
                    sx={{
                        color: 'primary.main',
                        fontFamily: 'inherit',
                        fontWeight: 900,
                        textAlign: 'center',
                        m: isMobile ? 0 : '2.2rem 0',
                    }}>
                    Vibio for all
                </Typography>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: 0,
                    padding: '1rem 0 0 0',
                    justifyContent: 'space-evenly',
                }}>
                    <Box sx={{
                        m: 0,
                        p: 0,
                    }}>
                        <CardMedia
                            component="video"
                            src="/mockup-cc.mp4"
                            title="Product showcase 1"
                            autoPlay
                            muted
                            loop
                            playsinline
                            sx={{
                                m: 0,
                                p: 0,
                                height: '100vh',
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                    <Box sx={{
                        m: 0,
                        p: 0,
                    }}>
                        <CardMedia
                            component="video"
                            src="/mockup-any.mp4"
                            title="Product showcase 2"
                            autoPlay
                            muted
                            loop
                            playsinline
                            sx={{
                                m: 0,
                                p: 0,
                                height: '100vh',
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                </div>
            </div>
        </>
    )

}
