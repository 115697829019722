import axios from 'axios'


const backendUrl = process.env.REACT_APP_BACKEND_URL
const DOMAIN = `${backendUrl}/api`

export async function getUserById(userId) {
    return (await axios.get(`${DOMAIN}/users/${userId}`)).data
}

export async function getUserByUsername(username) {
    try {
        const response = await axios.get(`${DOMAIN}/users/u/${username}`)
        // alert(response.data)
        return response.data
    } catch (error) {
        // alert('Error fetching user:', error)
        throw error
    }
}

export async function checkUsernameAvailability(username) {
    const isAvailable = await axios({
        method: "get",
        url: `${DOMAIN}/username/${username}`,
        headers: {
            'Content-Type': 'Application/json'
        },
    })
        .then(response => {
            console.log(response.data)
            const result = response.status === 200
            return [result, 'Looks Good!']
        })
        .catch(err => {
            if (err.response && (err.response.status === 409 || err.response.status === 400)) {
                console.log(err.response.data)
                const errMsg = err.response.data
                return [false, errMsg]
            } else {
                console.error(`Error occurred while checking username availability: ${err}`)
            }
        })
    return isAvailable
}


export async function submitUsername(userId, username) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }

    const isSubmitOk = await axios({
        method: "post",
        url: `${DOMAIN}/username/${userId}/${username}`,
        headers,
    })
        .then(response => {
            if (response.status === 204) {
                console.log("Username submission was successful")
                return true
            }
        })
        .catch(err => {
            console.error(`Error occurred while submitting username: ${err}`)
            return false
        })

    return isSubmitOk
}


export async function getVideoPage(username) {
    const videoPage = await axios({
        method: "get",
        url: `${DOMAIN}/vp/${username}`,
        headers: {
            'Content-Type': 'Application/json'
        },
    })
        .then(response => {
            if (response.status === 200) {
                console.log(`Fethed the video page for user ${username}`)
                return response.data
            } else {
                console.log(`Faild to fethed the video page for user ${username}`)
                return null
            }
        })
        .catch(err => {
            console.error(`Error occurred while fetching ${username}'s video page: ${err}`)
            return null
        })

    return videoPage
}


export async function updateUserBio(userId, bio) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }

    const isUpdateOK = await axios({
        method: "post",
        url: `${DOMAIN}/users/${userId}/bio/${bio}`,
        headers,
    })
        .then(response => {
            if (response.status === 204) {
                console.log("User bio update was successful")
                return true
            }
        })
        .catch(err => {
            console.error(`Error occurred while updating user bio: ${err}`)
            return false
        })

    return isUpdateOK
}


export async function updateUserProfilePicture(userId, formData) {
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }

    const pictureURL = await axios.post(`${DOMAIN}/users/${userId}/pp`, formData, { headers })
        .then(response => {
            console.log("Status: " + response.status)
            if (response.status === 200) {
                console.log("Uploading new profile picture was successful")
                console.log(response.data)
                return response.data
            }
        })
        .catch(err => {
            console.log("Status: " + err.response.status)
            console.error(`Error occurred while uploading profile picture: ${err}`)
            return null
        })

    return pictureURL
}

export async function removeUserProfilePicture(userId) {
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }

    const isRemoveOK = await axios.delete(
        `${DOMAIN}/users/${userId}/pp`,
        { headers }
    )
        .then(response => {
            if (response.status === 204) {
                console.log("Removed user profile picture")
                return true
            }
        })
        .catch(err => {
            console.error(`Error accurred while removing profile picture: ${err}`)
            return false
        })

    return isRemoveOK
}
