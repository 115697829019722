import React from 'react'
import { Container, Typography, Box } from '@mui/material'
import Navbar from '../../nav/Navbar'
import Footer from '../../landing/Footer'

function TermsOfService() {
    return (
        <>
            <Navbar />
            <Container component="main" maxWidth="md">
                <Box component="section" mt={4.2}>
                    <Typography component="h1" variant="h4" gutterBottom>
                        Terms of Service
                    </Typography>

                    <Typography component="p" variant="subtitle1">
                        <strong>Effective Date:</strong> October 9th, 2024
                    </Typography>
                    <Typography component="p" variant="subtitle1">
                        Welcome to Vibio! These Terms of Service ("Terms") govern your use of the Vibio platform ("Service"), so please read them carefully.
                        By using or accessing Vibio, you agree to these Terms. If you do not agree, do not use the Service.
                    </Typography>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            1. Acceptance of Terms
                        </Typography>
                        <Typography component="p" variant="body1">
                            By accessing or using Vibio, you acknowledge that you have read, understood, and agree to be bound by these Terms.
                            Vibio reserves the right to update these Terms at any time with notice. Your continued use of the Service constitutes your acceptance of the revised Terms.
                        </Typography>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            2. Description of the Service
                        </Typography>
                        <Typography component="p" variant="body1">
                            Vibio is a video integrated link-in-bio tool that allows you to upload videos that play automatically in a loop on your page. You can add links to social accounts,
                            calendars, websites, and more. We encourage you to get creative with the videos you upload to your Vibio page.
                        </Typography>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            3. Early Release
                        </Typography>
                        <Typography component="p" variant="body1">
                            Vibio is in "Early Release". As an early user, you may find some features incomplete or missing. Your feedback is valuable
                            and will help shape future versions of Vibio.
                        </Typography>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            4. User Responsibilities
                        </Typography>
                        <ul>
                            <Typography component="li" variant="body1">
                                You are responsible for ensuring that any content you upload does not infringe on third-party rights or violate laws.
                            </Typography>
                            <Typography component="li" variant="body1">
                                You agree not to use Vibio for any illegal or unauthorized purposes.
                            </Typography>
                            <Typography component="li" variant="body1">
                                You agree to provide accurate information when creating an account and to keep your login credentials secure.
                            </Typography>
                            <Typography component="li" variant="body1">
                                <strong>Explicit Content Prohibition:</strong> Under no circumstances may you upload explicit images, videos,
                                or any links navigating to adult sites.
                            </Typography>
                        </ul>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            5. Content and Intellectual Property
                        </Typography>
                        <Typography component="p" variant="body1">
                            Any video or profile image you upload to your Vibio page will be publicly visible.
                            You retain ownership of the content you upload, but by uploading content, you grant Vibio a non-exclusive,
                            royalty-free license to display your content on your Vibio page.
                            Vibio reserves the right to remove content that violates these Terms or any applicable laws.
                        </Typography>

                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            6. Changes to the Service
                        </Typography>
                        <Typography component="p" variant="body1">
                            Vibio may, at its sole discretion, modify, update, or discontinue the Service with notice. Vibio is not liable for
                            any changes or interruptions to the Service.
                        </Typography>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            7. Pricing and Payment
                        </Typography>
                        <Typography component="p" variant="body1">
                            Although "Early Release" is free to use, Vibio reserves the right to change pricing for any of its services. If pricing changes,
                            you will be notified in advance, and continued use of the Service will be deemed as acceptance of the new pricing.
                        </Typography>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            8. Termination
                        </Typography>
                        <Typography component="p" variant="body1">
                            Vibio may terminate or suspend your access to the Service at any time, without notice, if you breach these Terms, upload
                            explicit content, or engage in any activities that harm the Service or its users.
                        </Typography>
                    </Box>

                    <Box component="section" mt={3} mb={6}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            9. Contact Us
                        </Typography>
                        <Typography component="p" variant="body1">
                            If you have any questions regarding these Terms, please contact us at <a href='mailto:info@vibio.co'>info@vibio.co</a>.
                        </Typography>
                    </Box>
                </Box>
            </Container>
            <Footer />
        </>
    )
}

export default TermsOfService
