import { Box, Button, Divider, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"

// const navigation = ["Explore", "Pricing", "Log In", "Contact Us"]
const bottomNav = [
    { title: "Terms", nav: '/terms' },
    { title: "Privacy", nav: '/privacy' },
    { title: "Log In", nav: '/login' },
]

export default function Footer() {
    const navigate = useNavigate()

    return (
        <>
            <Box sx={{
                backgroundColor: 'primary.main',
                color: 'text.regular',
                fontFamily: 'Catamaran',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                alignItems: 'center',
                p: 1,
                gap: 1,
            }}>

                <Box sx={{
                }}>
                    {bottomNav.map((el) =>
                        <Button
                            type="button"
                            variant="text"
                            disableElevation
                            size='small'
                            sx={{
                                fontFamily: 'inherit',
                                backgroundColor: 'primary.main',
                                color: 'text.regular',
                                textTransform: 'none',
                            }}
                            onClick={() => navigate(`${el.nav}`)}
                        >
                            {el.title}
                        </Button>
                    )}
                    <Button
                        type="button"
                        variant="text"
                        disableElevation
                        size='small'
                        sx={{
                            fontFamily: 'inherit',
                            backgroundColor: 'primary.main',
                            color: 'text.regular',
                            textTransform: 'none',
                        }}
                    >
                        <a 
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        href="mailto:info@vibio.co">
                            Contact Us
                        </a>
                    </Button>

                </Box>

                {/* <Divider variant="middle" sx={{ color: 'primary.second', width: '80%' }} component="div" /> */}

                <Box sx={{}}>
                    <Typography variant='body1' component="p" sx={{
                        fontFamily: 'inherit',
                        fontWeight: 400,
                        color: 'inherit',
                    }}>
                        Vibio &copy; {new Date().getFullYear()} All Rights Reserved
                    </Typography>
                </Box>
            </Box>
        </>
    )
}
