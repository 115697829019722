import React, { useEffect } from 'react'

const BrowserRedirect = ({ children }) => {
    useEffect(() => {
        // Check if we're in Instagram's browser
        const userAgent = navigator.userAgent || navigator.vendor || window.opera
        const isInstagram = userAgent.indexOf('Instagram') > -1
        const isFacebook = userAgent.indexOf('FBAN') > -1 || userAgent.indexOf('FBAV') > -1

        if (isInstagram || isFacebook) {
            const currentUrl = window.location.href

            // For Android
            if (/android/i.test(userAgent)) {
                window.location.href = `intent:${currentUrl}#Intentend`
            }
            // For iOS
            else if (/iphone|ipad|ipod/i.test(userAgent)) {
                window.location.href = `x-safari-${currentUrl}#Intentend`
            }
        }
    }, [])

    return children
}

export default BrowserRedirect
