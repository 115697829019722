import { Typography, useMediaQuery } from "@mui/material";

export default function ProblemStatement() {
    const isMobile = useMediaQuery('(max-width:1050px)')

    return (
        <>
            <article style={{
                margin: isMobile ? '.8rem' : '0 10vw',
                borderRadius: '20px',
                background: '#f4f4f4',
                padding: isMobile ? '2rem 1rem' : '2rem',
            }}>
                <Typography variant={isMobile ? 'h4' : 'h3'} component="h3"
                    sx={{
                        color: 'primary.main',
                        fontFamily: 'inherit',
                        fontWeight: 900,
                        textAlign: 'center',
                        m: '0 0 2rem 0',
                    }}>
                    Why Vibio?
                </Typography>
                <Typography variant="h6" component="h6"
                    sx={{
                        color: 'text.second',
                        fontFamily: 'inherit',
                        fontWeight: 500,
                        p: isMobile ? '0' : '0 2rem',
                    }}>
                    We believe the link-in-bio pages can be more engaging and fun.
                    Vibio allows you to get creative and show who you are, what products
                    or services your offer to your audience.
                </Typography>
            </article>
        </>
    )
}
