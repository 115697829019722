import React, { useState } from 'react'
import { Box, Button, Typography, Modal, TextField, useMediaQuery } from '@mui/material'

export function AddLinkButonModal ({ open, onClose, onAddLink }) {
    const [title, setTitle] = useState('')
    const [url, setUrl] = useState('')
    const isMobile = useMediaQuery('(max-width:1050px)')


    const handleAddLink = () => {
        if (title && url) {
            onAddLink({ title, url })
            setTitle('')
            setUrl('')
            onClose()
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? 300 : 400,
                bgcolor: 'background.paper',
                borderRadius: '10px',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add new link
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label="Title"
                    name="title"
                    autoComplete="title"
                    autoFocus
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="url"
                    label="URL"
                    name="url"
                    autoComplete="url"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddLink}
                    sx={{ mt: 2 }}
                >
                    Add
                </Button>
            </Box>
        </Modal>
    )
}
