import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Box, Divider, Typography, CircularProgress, useMediaQuery, Button } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'


import StudioNav from '../../nav/StudioNav'
import TabSection from '../../tabs/TabSection'
import { useAuth } from '../../../context/SecurityContextProvider'
import { VideoPagePreview } from '../videopage/preview/VideopagePreview'
import { LinksProvider } from '../../../context/LinkProvider'
import { MediaProvider } from '../../../context/MediaProvider'

export default function StudioView() {
    const { userData, loading, isAuthenticated } = useAuth()
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:1050px)')


    useEffect(() => {
        if (userData !== undefined) {
            if (userData.didCompleteSetup === false) {
                navigate('/welcome')
            }
        }
    }, [])

    if (loading) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <CircularProgress />
            </Box>
        )
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />
    }


    const videoPage = userData.videoPages && userData.videoPages[0]
    const videoSrc = videoPage ? videoPage.videoSrc : ""


    return (
        <>
            <LinksProvider user={userData}>
                <MediaProvider user={userData}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <StudioNav />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            m: 0,
                            p: 0,
                            gap: 1,
                            height: '92vh',
                            fontFamily: 'Catamaran',
                            backgroundColor: 'primary.bg',
                        }}>
                            {/* L */}
                            <Box sx={{
                                m: 0,
                                p: 0,
                                flex: 3,
                                color: 'text.second',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: isMobile ? '' : 'center',
                            }}>
                                <Box>
                                    <section style={{
                                        display: isMobile ? 'flex' : 'block',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around'
                                    }}>
                                        <Typography
                                            variant="h6"
                                            component="h5"
                                            sx={{
                                                fontWeight: 400,
                                                fontFamily: 'inherit',
                                                textAlign: 'left',
                                                m: '1rem 1rem .4rem 1rem',
                                            }}
                                        >
                                            Welcome back,
                                            <span style={{ fontWeight: 500 }}>
                                                {" " + userData?.username}
                                            </span>
                                            !
                                        </Typography>
                                        {isMobile &&
                                            <Button
                                                variant="contained"
                                                onClick={() => navigate(`/${userData?.username}?preview=true`)}
                                                startIcon={<VisibilityIcon sx={{ fontSize: 16 }} />}
                                                sx={{
                                                    m: '.6rem 1rem .6rem .8rem',
                                                    px: 1.2,  // horizontal padding
                                                    py: 0.5, // vertical padding
                                                    textTransform: "none",
                                                    backgroundColor: '#0f0f0f',
                                                    color: 'primary.bg',
                                                    borderRadius: '24px',
                                                    boxShadow: 'none',
                                                    fontSize: '0.6rem',
                                                }}
                                            >
                                                Preview
                                            </Button>
                                        }
                                    </section>
                                    <Divider />
                                    <TabSection user={userData} />
                                </Box>
                            </Box>

                            {/* R */}
                            {
                                !isMobile &&
                                <Box sx={{
                                    flex: 2,
                                    backgroundColor: 'primary.second',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'start',
                                    m: 0,
                                    p: '1.8rem 2rem',
                                }}>
                                    <div style={{ position: 'sticky', top: '2.2rem' }}>
                                        <VideoPagePreview
                                            userData={userData}
                                            videoSrc={videoSrc}
                                        />
                                    </div>
                                </Box>
                            }
                        </Box>
                    </Box>
                </MediaProvider>
            </LinksProvider>
        </>
    )
}