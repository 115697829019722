import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LogoutIcon from '@mui/icons-material/Logout'
import MovieFilterIcon from '@mui/icons-material/MovieFilter'

import { useAuth } from '../../context/SecurityContextProvider'
import { ListItemIcon, ListItemText } from '@mui/material'


export default function DropdownButton({ username }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const navigate = useNavigate()
    const { logout } = useAuth()


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => setAnchorEl(null)

    const handleStudio = () => {
        handleClose()
        navigate("/studio")
    }

    const handleLogout = () => {
        logout()
        handleClose()
        navigate("/")
    }

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<ExpandMoreIcon />}
                sx={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    alignSelf: 'center',
                    padding: '.4rem 1rem',
                    borderRadius: '10px',
                    background: '#fff',
                    color: '#000',
                    fontFamily: 'inherit',
                    fontSize: '.9rem',
                    fontWeight: 500,
                    ":hover": {
                        backgroundColor: 'primary.bg',
                    },
                }}
            >
                {username}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleStudio}>
                    <ListItemText>Studio</ListItemText>
                    <ListItemIcon sx={{ ml: 'auto', color: '#000' }}>
                        <MovieFilterIcon fontSize="small" />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={handleLogout} sx={{
                    gap: 1
                }}>
                    <ListItemText>Log out</ListItemText>
                    <ListItemIcon sx={{ ml: 'auto', color: '#000' }}>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                </MenuItem>
            </Menu>
        </>
    )
}
