import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Box, Button, Paper, Typography } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import Swal from "sweetalert2"
import { useState, useRef } from "react"
import { UpdateLinkButtonModal } from "../tabs/links/modals/linkbutton/UpdateLinkButtonModal"
import { LinkButton } from "../../model/LinkButton"

export function LinkButtonStudio({ uuid, id, title, url, onUpdate, onDelete }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
    const [open, setOpen] = useState(false)
    const clickTimeoutRef = useRef(null)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleUpdateLink = async (updatedLink) => {
        const newLinkButton = new LinkButton(
            uuid,
            id,
            updatedLink.newTitle,
            updatedLink.newUrl,
            ""
        )
        onUpdate(newLinkButton)
        handleClose()
    }

    const handleDelete = () => {
        Swal.fire({
            title: `Delete Link '${title}'?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            confirmButtonColor: "red",
        }).then((result) => {
            if (result.isConfirmed) {
                onDelete(uuid)
            }
        })
    }

    const style = {
        background: '#f8f8f8',
        borderRadius: '6px',
        marginTop: '1rem',
        marginBottom: '1rem',
        padding: '.3rem',
        transition,
        transform: CSS.Transform.toString(transform),
        display: 'flex',
        justifyContent: 'space-between',
        border: '1.2px solid #777',
        touchAction: 'none',
    }

    const handleMouseDown = (e) => {
        clickTimeoutRef.current = setTimeout(() => {
            clickTimeoutRef.current = null
        }, 200)
    }

    const handleMouseUp = (e, action) => {
        if (clickTimeoutRef.current) {
            clearTimeout(clickTimeoutRef.current)
            action()
        }
    }

    return (
        <>
            <Paper
                elevation={3}
                ref={setNodeRef}
                {...attributes}
                {...listeners}
                style={style}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 2,
                    flex: 3,
                }}>
                    <div>
                        <MoreVertIcon fontSize="medium" sx={{ cursor: 'grab', padding: '.4rem', m: 0 }} />
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            component="h5"
                            sx={{
                                fontWeight: 600,
                                fontFamily: 'Catamaran',
                                textAlign: 'left',
                                textTransform: 'none',
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="body1"
                            component="p"
                            sx={{
                                fontWeight: 500,
                                fontFamily: 'Catamaran',
                                textAlign: 'left',
                                textTransform: 'none',
                            }}
                        >
                            {url}
                        </Typography>
                    </div>
                </Box>
                <Box sx={{
                    alignSelf: 'center',
                    justifySelf: 'flex-end',
                    flex: .6,
                    justifyContent: 'center'
                }}>
                    <Button
                        variant="text"
                        onMouseDown={handleMouseDown}
                        onMouseUp={(e) => handleMouseUp(e, handleOpen)}
                    >
                        <EditIcon fontSize="small" sx={{ color: '#000' }} />
                    </Button>
                    <Button
                        variant="text"
                        color="error"
                        onMouseDown={handleMouseDown}
                        onMouseUp={(e) => handleMouseUp(e, handleDelete)}
                    >
                        <DeleteOutlineIcon fontSize="small" />
                    </Button>
                </Box>
            </Paper>

            <UpdateLinkButtonModal
                open={open}
                onClose={handleClose}
                onUpdateLink={handleUpdateLink}
                title={title}
                url={url}
            />
        </>
    )
}
