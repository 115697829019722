import axios from 'axios'


const backendUrl = process.env.REACT_APP_BACKEND_URL
const DOMAIN = `${backendUrl}/api/vp`


/**
 * Uploads a new video to a specific video page.
 * 
 * This function makes an asynchronous POST request to upload a video file to the server.
 * The `videoPageId` specifies the page where the video will be uploaded, and `formData` contains
 * the video file and other relevant information. It returns a boolean indicating the success of the upload
 * and the video source URL if the upload is successful.
 * 
 * @param {string} videoPageId - The ID of the video page where the video will be uploaded.
 * @param {FormData} formData - The form data containing the video file to be uploaded.
 * 
 * @returns {Promise<[boolean, string]>} A promise that resolves to a tuple:
 *  - The first element is a boolean (`isUploadOK`) indicating whether the upload was successful (`true`) or failed (`false`).
 *  - The second element is the video source URL (`videoSrc`) if the upload is successful, otherwise an empty string.
 * 
 * @example
 * const [isUploadOK, videoSrc] = await uploadNewVideo('12345', formData);
 * if (isUploadOK) {
 *     console.log(`Video uploaded successfully: ${videoSrc}`);
 * } else {
 *     console.log('Video upload failed.');
 * }
 */
export async function uploadNewVideo(videoPageId, formData) {
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }

    let videoSrc = ""
    const isUploadOK = await axios.post(`${DOMAIN}/${videoPageId}/video`, formData, { headers })
        .then(response => {
            console.log("Status: " + response.status)
            if (response.status === 200) {
                console.log("Uploading new video was successful")
                videoSrc = response.data
                return true
            }
        })
        .catch(err => {
            console.log("Status: " + err.response.status)
            console.error(`Error occurred while uploading video: ${err}`)
            return false
        })

    return [isUploadOK, videoSrc]
}

