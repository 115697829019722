import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFacebook,
    faVine,
    faInstagram,
    faTiktok,
    faXTwitter,
    faTwitter,
    faWhatsapp,
    faYoutube,
    faTwitch,
    faAmazon,
    faEtsy,
    faEbay,
    faReddit,
    faSnapchat,
    faTumblr,
    faSpotify,
    faGithub,
    faPinterest,
    faDiscord,
    faLinkedin,
    faPaypal,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from '@mui/material'


const iconList = [
    { id: 0, icon: faEnvelope, color: '#000' },
    { id: 1, icon: faInstagram, color: '#E1306C' },
    { id: 2, icon: faFacebook, color: '#1877F2' },
    { id: 3, icon: faXTwitter, color: '#000' },
    { id: 4, icon: faTiktok, color: '#000' },
    { id: 5, icon: faPinterest, color: '#c30010' },
    { id: 6, icon: faSnapchat, color: '#fffc00' },
    { id: 7, icon: faReddit, color: '#FF4500' },
    { id: 8, icon: faWhatsapp, color: '#25D366' },
    { id: 9, icon: faTumblr, color: '#34526f' },
    { id: 10, icon: faYoutube, color: '#FF0000' },
    { id: 11, icon: faTwitch, color: '#6441a5' },
    { id: 12, icon: faDiscord, color: '#4e5d94' },
    { id: 13, icon: faSpotify, color: '#1DB954' },
    { id: 14, icon: faAmazon, color: '#000' },
    { id: 15, icon: faEbay, color: '#000' },
    { id: 16, icon: faEtsy, color: '#eb6d20 ' },
    { id: 17, icon: faGithub, color: '#000' },
    { id: 18, icon: faLinkedin, color: '#0077B5' },
    { id: 19, icon: faPaypal, color: '#00457C' },
    { id: 20, icon: faVine, color: '#00ffbe' },
    { id: 21, icon: faGlobe, color: '#000' },
]

export function SocialLinkList({ socials }) {
    const handleLinkVisit = (url) => window.open(url)
    const isMobile = useMediaQuery('(max-width:1050px)')

    const styleDefault = {
        display: 'flex',
        justifyContent: 'center',
        gap: '.1rem',
        marginBottom: '.7rem',
        padding: '0 .8rem 0 .8rem',
    }

    const styleMany = {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '.1rem',
        marginBottom: '.7rem',
        padding: '0 .8rem 0 .8rem',
        width: isMobile ? '80vw' : '620px',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        scrollBehavior: 'smooth',
        // scrollbarWidth: 'none',
        msOverflowStyle: 'auto',
    }

    return (
        <>
            <div style={styleDefault}>
                {socials.map(social => (
                    social.url &&
                    <FontAwesomeIcon
                        key={social.id}
                        icon={iconList[social.icon].icon}
                        size='2x'
                        style={{
                            margin: '.8rem',
                            padding: '.2rem',
                            borderRadius: '10px',
                            cursor: 'pointer',
                            color: `${iconList[social.icon].color}`,
                        }}
                        onClick={() => handleLinkVisit(`${social.url}`)}
                    />
                ))}
            </div>
        </>
    )
}
