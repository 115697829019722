import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../../context/SecurityContextProvider'
import { CircularProgress, Box } from '@mui/material'

export default function ProtectedRoutes() {
    const { isAuthenticated, loading } = useAuth()

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        )
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />
}
