export class SocialLink {
    
    /**
     * @param {string} id
     * @param {string} url
     * @param {number} icon
     * @param {string} videoPageId
     */

    constructor(id, url, icon, videoPageId) {
        this.id = id
        this.url = url
        this.icon = icon
        this.videoPageId = videoPageId
    }

}