import { Avatar, Box, Button, Chip, Paper, useMediaQuery } from "@mui/material"
import { useContext } from "react"

import { BioSettings } from "./BioSettings"
import { updateUserBio } from "../../../service/UserDataService"
import { useAuth } from "../../../context/SecurityContextProvider"
import { MediaContext } from "../../../context/MediaProvider"


export default function ProfileSettings() {
    const { userData } = useAuth()
    const {
        handleDeleteProfilePic,
        handleUpdateProfilePic,
        profilePicture,
    } = useContext(MediaContext)
    const isMobile = useMediaQuery('(max-width:1050px)')


    const handleBioUpdate = async (newBio) => {
        const updateSuccessfull = await updateUserBio(userData.userId, newBio)
        if (updateSuccessfull) {
            userData.bio = newBio
        }
    }

    return (
        <>
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    m: isMobile ? 0 : 2,
                    borderRadius: '10px',
                }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    <Avatar
                        alt={userData.username}
                        src={profilePicture}
                        sx={{ width: 80, height: 80, m: .6 }}
                    />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: '0 1rem',
                    }}>
                        <Button
                            variant='outlined'
                            type='button'
                            color='error'
                            onClick={handleDeleteProfilePic}
                            sx={{
                                fontSize: '62%',
                                textTransform: 'none',
                                m: '.2rem 0',
                            }}
                        >
                            Remove
                        </Button>
                        <Button
                            variant='contained'
                            type='button'
                            sx={{
                                fontSize: '62%',
                                textTransform: 'none',
                                m: '.2rem 0',
                            }}
                            onClick={handleUpdateProfilePic}
                        >
                            Update
                        </Button>
                    </Box>
                </Box>

                <Chip
                    label={`@${userData.username}`}
                    color="primary"
                    size="small"
                    sx={{
                        m: '.8rem 0',
                        p: '.2rem',
                    }}
                />

                <BioSettings
                    bioText={userData.bio}
                    onUpdate={handleBioUpdate}
                />
            </Paper>
        </>
    )
}
