import React, { useState, useRef } from 'react'
import { Chip } from '@mui/material'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import ProfileCard from '../../profile/ProfileCard'

export function MobileView({ videoSrc, socials, linkButtons, userData }) {
    const [isMuted, setMuted] = useState(true)
    const videoRef = useRef(null)

    const handleMute = () => setMuted(!isMuted)

    return (
        <>
            <div style={{
                position: 'relative',
                height: '100vh',
                width: '100%',
                overflow: 'hidden'
            }}>
                <Chip
                    onClick={handleMute}
                    icon={isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                    sx={{
                        m: 0,
                        p: 0,
                        position: 'absolute',
                        top: '16px',
                        right: '16px',
                        zIndex: 1,
                        color: '#fff',
                        backgroundColor: 'transparent',
                        '& .MuiChip-icon': {
                            color: 'primary.bg',
                        }
                    }}
                />
                <video
                    style={{
                        width: '100%',
                        height: '100vh',
                        objectFit: 'cover'
                    }}
                    src={videoSrc}
                    autoPlay
                    playsInline
                    loop
                    ref={videoRef}
                    muted={isMuted}
                />
                <ProfileCard
                    socials={socials}
                    linkButtons={linkButtons}
                    userData={userData}
                />
            </div>
        </>
    )
}
