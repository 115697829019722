import React, { useState } from 'react'
import { Box, Button, Collapse, Typography } from '@mui/material'
import { SocialLinkList } from '../links/SocialLinkList'
import { Profile } from './Profile'

export default function ProfileCard({ userData, socials, linkButtons }) {

    const [isProfileExpanded, setProfileExpanded] = useState(false)
    const toggleProfile = () => { setProfileExpanded(!isProfileExpanded) }

    if (!userData) {
        return <Typography>Loading user data...</Typography>
    }

    return (
        <Button onClick={toggleProfile}
            sx={{
                width: '100%',
                padding: 0,
                textTransform: 'none',
                color: 'text.second'
            }}>
            <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: '.2rem',
                right: '.2rem',
                paddingBottom: '1rem',
                backgroundColor: 'primary.bg',
                borderRadius: '20px',
                maxHeight: '88vh',
            }}>
                <div style={{
                    textAlign: 'center',
                    width: '100vw',
                    marginRight: '1rem'
                }}>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            fontWeight: 400,
                            fontFamily: 'Catamaran',
                            textAlign: 'center',
                            textTransform: 'none',
                            fontSize: '90%',
                            p: .4,
                        }}
                    >
                        {isProfileExpanded ? '' : 'tap to expand'}
                    </Typography>
                </div>

                {/* profile */}
                <Profile
                    isFullView={isProfileExpanded}
                    userData={userData}
                />

                {/* social links */}
                <SocialLinkList socials={socials} />

                {/* link buttons */}
                <Collapse in={isProfileExpanded}>
                    <div style={{
                        overflowY: 'auto',
                        maxHeight: '50vh',
                    }}>
                        {
                            linkButtons
                                .sort((a, b) => a.position - b.position)
                                .map((btn) => (
                                    <Button key={btn.id} href={btn.url} sx={{
                                        display: 'block',
                                        backgroundColor: 'links.btn',
                                        color: 'text.regular',
                                        ":hover": {
                                            backgroundColor: '#FF0271',
                                        },
                                        m: '1rem 1.2rem',
                                        p: '.6rem',
                                    }}>
                                        <Typography
                                            variant="body1"
                                            component="p"
                                            sx={{
                                                fontWeight: 600,
                                                fontFamily: 'Catamaran',
                                                textAlign: 'center',
                                                textTransform: 'none',
                                            }}
                                        >
                                            {btn.title}
                                        </Typography>
                                    </Button>
                                ))
                        }
                    </div>
                    {/* Vibio signature */}
                    <p style={{
                        padding: '.4rem',
                        margin: '.8rem 0',
                        alignSelf: 'center',
                        color: '#000',
                        fontSize: '1.4rem',
                        fontWeight: 550,
                        fontFamily: 'Catamaran',
                    }}>
                        <a href='https://vibio.co' style={{ textDecoration: 'none', color: 'inherit' }}>
                            Vibio
                        </a>
                    </p>
                </Collapse>
            </Box>
        </Button>
    )
}
