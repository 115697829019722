import React, { useState, useRef } from 'react'
import { Box, Chip, Divider, Typography } from '@mui/material'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import ProfileCard from '../../profile/ProfileCard'


export function DesktopView({ videoSrc, socials, linkButtons, userData }) {
    const [isMuted, setMuted] = useState(true)
    const videoRef = useRef(null)

    const handleMute = () => setMuted(!isMuted)

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                m: 0,
                p: 0,
                gap: 1,
                fontFamily: 'Catamaran',
                backgroundColor: 'primary.main',
            }}>
                {/* L */}
                <Box sx={{
                    flex: 6,
                    color: 'text.regular',
                    m: 1,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                            fontWeight: 900,
                            fontFamily: 'inherit',
                            textAlign: 'center',
                        }}
                    >
                        Vibio
                    </Typography>
                    <Typography
                        variant="h6"
                        component="h6"
                        sx={{
                            fontWeight: 500,
                            fontFamily: 'inherit',
                            textAlign: 'center',
                        }}
                    >
                        Video link-in-bio tool
                    </Typography>
                    <Divider
                        sx={{
                            border: '0.1px solid #f6f6f6',
                            width: '16%'
                        }} />
                    <Typography
                        variant="body1"
                        component="h5"
                        sx={{
                            fontWeight: 400,
                            fontFamily: 'inherit',
                            textAlign: 'center',
                        }}
                    >
                        looks better on your phone!
                    </Typography>
                </Box>

                {/* R */}
                <Box sx={{
                    flex: 3,
                    backgroundColor: 'primary.bg',
                }}>
                    <div style={{
                        position: 'relative',
                        height: '100vh',
                        width: '100%',
                        overflow: 'hidden'
                    }}>
                        <Chip
                            onClick={handleMute}
                            icon={isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                            sx={{
                                m: 0,
                                p: 0,
                                position: 'absolute',
                                top: '16px',
                                right: '16px',
                                zIndex: 1,
                                color: '#fff',
                                backgroundColor: 'transparent',
                                '& .MuiChip-icon': {
                                    color: 'primary.bg',
                                }
                            }}
                        />
                        <video
                            style={{
                                width: '100%',
                                height: '100vh',
                                objectFit: 'cover'
                            }}
                            src={videoSrc}
                            autoPlay
                            playsInline
                            loop
                            ref={videoRef}
                            muted={isMuted}
                        />
                        <ProfileCard
                            socials={socials}
                            linkButtons={linkButtons}
                            userData={userData}
                        />
                    </div>
                </Box>
            </Box>
        </>
    )
}
