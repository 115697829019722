import React from "react"
import { motion } from "framer-motion"
import { Avatar, Typography } from '@mui/material'


export function Profile({ isFullView, userData }) {

    return (
        <motion.div
            animate={{ y: 5, x: 6 }}
            style={{
                display: 'flex',
                flexDirection: isFullView ? 'column' : 'row',
                alignItems: isFullView ? 'center' : 'center',
                paddingBottom: isFullView ? '2rem' : '.8rem',
            }}
        >
            <Avatar
                alt="User profile pic"
                src={userData?.profilePicture}
                sx=
                {{
                    width: '3.9rem',
                    height: '3.9rem',
                    m: '.6rem .2rem',
                }}
            />
            <motion.div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {userData?.username && (
                    <Typography
                        variant="body1"
                        style={{ margin: '.4rem .2rem' }}
                    >
                        @{userData.username}
                    </Typography>
                )}
            </motion.div>
            {/* bio */}
            {isFullView && userData?.bio && (
                <Typography variant="body2">
                    {userData.bio}
                </Typography>
            )}
        </motion.div>
    )
}
