import React from 'react'
import axios from 'axios'
import { GoogleLogin } from '@react-oauth/google'
import { useAuth } from '../../../context/SecurityContextProvider'
import { useNavigate } from 'react-router-dom'


function GoogleLoginButton() {
    const { login } = useAuth()
    const navigate = useNavigate()

    const handlePostGoogleLogin = (response) => {
        const idToken = response?.credential
        if (!idToken) {
            console.error('No access token found')
            return
        }

        const URL = `${process.env.REACT_APP_BACKEND_URL}/api/auth/google`

        axios.post(
            URL,
            idToken,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then((response) => {
                const loginData = { token: idToken, userData: response.data }
                login(loginData)
                if (response.status === 201) {
                    navigate('/welcome')
                } else if (response.status === 200) {
                    console.log("User already exists")
                    const isSetupOK = response.data.setupComplete
                    if (isSetupOK) {
                        navigate('/studio')
                    } else {
                        navigate('/welcome')
                    }
                }
            })
            .catch((error) => console.error('Error sending token to backend:', error))
    }

    return (
        <GoogleLogin onSuccess={handlePostGoogleLogin} />
    )
}

export default GoogleLoginButton
