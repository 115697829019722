import React, { useState, useEffect } from 'react'
import { Box, Button, LinearProgress, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../../context/SecurityContextProvider'
import { checkUsernameAvailability, submitUsername } from '../../../service/UserDataService'


export default function ProfileSetUpView() {
    const navigate = useNavigate()
    const { userData } = useAuth()
    const [isLoading, setIsLoading] = useState(true)
    const [username, setUsername] = useState('')
    const [isUsernameValid, setIsUsernameValid] = useState(null)
    const [typingInterval, setTypingInterval] = useState(100)
    const [doneTypingInterval, setDoneTypingInterval] = useState(500)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (userData !== undefined) {
            setIsLoading(false)
            if (userData.didCompleteSetup) {
                navigate('/studio')
            }
        }
    }, [userData, navigate])

    useEffect(() => {
        if (username.length === 0) {
            setIsUsernameValid(false);
        }
    }, [username])

    const handleInputChange = (e) => {
        clearTimeout(typingInterval)

        const checkAvailability = async (uname) => {
            const [isAvailable, errorMsg] = await checkUsernameAvailability(uname)
            setErrorMessage(errorMsg)
            if (isAvailable) {
                setIsUsernameValid(true)
                setUsername(uname)
            } else {
                setIsUsernameValid(false)
            }
            console.log(isAvailable)
        }

        const val = e.target.value
        if (val) {
            setTypingInterval(setTimeout(() => checkAvailability(val), doneTypingInterval))
        }
        setUsername(e.target.value)
    }

    const handleUsernameSubmission = async () => {
        const isSubmissionOK = await submitUsername(userData.userId, username)

        if (isSubmissionOK) {
            userData.username = username
            // console.log("\nAFTER USERNAME IS CHOSEN\n")
            userData.didCompleteSetup = true
            navigate("/studio")
        } else {
            console.error("Oops! Something went wrong. Please try again.")
        }
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                m: 0,
                p: 0,
                gap: 1,
                height: '100vh',
                fontFamily: 'Catamaran',
                backgroundColor: 'primary.main',
            }}>
                {/* L */}
                <Box sx={{
                    flex: 2,
                    color: 'text.regular',
                    m: 1,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Typography
                        variant='h1'
                        component='h1'
                        sx={{
                            fontWeight: 900,
                            fontFamily: 'inherit',
                            textAlign: 'center',
                        }}
                    >
                        Vibio
                    </Typography>
                    <Typography
                        variant='h6'
                        component='h6'
                        sx={{
                            fontWeight: 500,
                            fontFamily: 'inherit',
                            textAlign: 'center',
                        }}
                    >
                        Video link-in-bio tool
                    </Typography>
                </Box>

                {/* R */}
                <Box sx={{
                    flex: 3,
                    backgroundColor: 'primary.bg',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {/* Profile Set Up Container */}
                    <Box sx={{
                        textAlign: 'center',
                        m: 1,
                        p: 2,
                    }}>
                        {isLoading ?
                            <LinearProgress sx={{ color: 'primary.main' }} />
                            :
                            <>
                                <Typography
                                    variant='h4'
                                    component='h4'
                                    sx={{
                                        fontWeight: 700,
                                        fontFamily: 'inherit',
                                        textAlign: 'center',
                                        mb: 2,
                                        '& span': {
                                            color: 'inherit'
                                        }
                                    }}
                                >
                                    Welcome to Vibio, <span style={{
                                        textDecoration: '3px underline #FF1493'
                                    }}>
                                        {userData?.username?.split(' ')[0] || ''}
                                    </span>!
                                </Typography>
                                <Typography
                                    variant='body1'
                                    component='p'
                                    sx={{
                                        fontWeight: 400,
                                        fontFamily: 'inherit',
                                        textAlign: 'center',
                                    }}
                                >
                                    Choose your username
                                </Typography>
                                {/* text field */}
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    m: 2,
                                    p: 1,
                                    gap: 2,
                                }}>

                                    <TextField
                                        fullWidth
                                        label="username"
                                        variant="outlined"
                                        value={username}
                                        onChange={handleInputChange}
                                    />
                                    <Typography variant='subtitle1' component='p'>
                                        {
                                            !username ?
                                                <p></p>
                                                :
                                                isUsernameValid ?
                                                    <p style={{ color: 'green' }}>Looks good!</p>
                                                    :
                                                    <p>{errorMessage}</p>
                                        }
                                    </Typography>
                                    <Button
                                        variant='contained'
                                        type='button'
                                        disabled={!isUsernameValid}
                                        onClick={handleUsernameSubmission}
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            </>}
                        {isLoading && <p>please wait</p>}
                    </Box>
                </Box>
            </Box>
        </>
    )
}
