import React from 'react'
import { Container, Typography, Box } from '@mui/material'
import Navbar from '../../nav/Navbar'
import Footer from '../../landing/Footer'

function PrivacyPolicy() {
    return (
        <>
            <Navbar />
            <Container component="main" maxWidth="md">
                <Box component="section" mt={4.2}>
                    <Typography component="h1" variant="h4" gutterBottom>
                        Privacy Policy
                    </Typography>

                    <Typography component="p" variant="subtitle1">
                        <strong>Effective Date:</strong> October 9th, 2024
                    </Typography>
                    <Typography component="p" variant="subtitle1">
                        This Privacy Policy explains how Vibio collects, uses, and protects your personal information when you use its Service.
                    </Typography>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            1. Information We Collect
                        </Typography>
                        <Typography component="p" variant="body1">
                            We only collect the data that is necessary to operate and improve the Vibio platform. This includes:
                            <ul>
                                <Typography component="li" variant="body1">
                                    <b>Account Information:</b> When you sign up for Vibio, we collect your name, email address, and other information you provide.
                                </Typography>
                                <Typography component="li" variant="body1">
                                    <b>Content:</b> Any videos, profile images, or other content you upload to Vibio.
                                </Typography>
                                <Typography component="li" variant="body1">
                                    <b>Usage Information:</b> We collect data on how you interact with Vibio to improve the service, including IP addresses, browser type, and device information.
                                </Typography>
                            </ul>
                        </Typography>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            2. How We Use Your Information
                            <Typography component="p" variant="body1">
                                We use your data to:
                                <ul>
                                    <Typography component="li" variant="body1">
                                        Operate and maintain the Vibio platform.
                                    </Typography>
                                    <Typography component="li" variant="body1">
                                        Communicate with you about your account or feedback.
                                    </Typography>
                                    <Typography component="li" variant="body1">
                                        Improve and personalize your experience on Vibio.
                                    </Typography>
                                </ul>
                            </Typography>
                        </Typography>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            3. Public Content
                        </Typography>
                        <Typography component="p" variant="body1">
                            Any videos or profile images you upload to Vibio are publicly visible.
                            Please ensure that any content you upload does not contain sensitive or personal information that you do not want shared publicly.
                        </Typography>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            4. Data Sharing
                        </Typography>
                        <Typography component="p" variant="body1">
                            Vibio does NOT share or sell your data to any third parties for marketing purposes. We may share data with trusted partners to help us
                            operate the Service (e.g., hosting providers) but only when necessary, and always under strict confidentiality agreements.
                        </Typography>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            5. Data Security
                        </Typography>
                        <Typography component="p" variant="body1">
                            We take reasonable measures to protect your personal data from unauthorized access or disclosure. However, no online platform can
                            guarantee complete security. By using Vibio, you acknowledge that you share data at your own risk.
                        </Typography>

                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            6. Data Retention
                        </Typography>
                        <Typography component="p" variant="body1">
                            We retain your personal data only as long as it is necessary to provide the Service or comply with legal obligations.
                        </Typography>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            7. Your Rights
                        </Typography>
                        <Typography component="p" variant="body1">
                            You have the right to:
                            <ul>
                                <Typography component="li" variant="body1">
                                    Access, correct, or delete your personal information.
                                </Typography>
                                <Typography component="li" variant="body1">
                                    Request information on how your data is used and shared.
                                </Typography>
                                <Typography component="li" variant="body1">
                                    Request deletion of your account and any associated data by sending an email to <a href='mailto:info@vibio.co'>info@vibio.co</a>.
                                </Typography>
                            </ul>
                        </Typography>
                    </Box>

                    <Box component="section" mt={3}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            8. Prohibited Content
                        </Typography>
                        <Typography component="p" variant="body1">
                            Under no circumstances are users allowed to upload explicit images, videos, or links that navigate to adult or pornographic websites.
                            Any such violation will result in immediate account termination.
                        </Typography>
                    </Box>

                    <Box component="section" mt={3} mb={6}>
                        <Typography component="h2" variant="h5" gutterBottom>
                            9. Contact Us
                        </Typography>
                        <Typography component="p" variant="body1">
                            If you have any questions or concerns about this Privacy Policy, please contact us at <a href='mailto:info@vibio.co'>info@vibio.co</a>.
                        </Typography>
                    </Box>
                </Box>
            </Container>
            <Footer />
        </>
    )
}

export default PrivacyPolicy
