
export class LinkButton {

    /**
     * @param {string} uuid
     * @param {number} id
     * @param {string} title
     * @param {string} url
     * @param {string} thumbnail
     */

    constructor(uuid, id, title, url, thumbnail) {
        this.uuid = uuid
        this.id = id
        this.title = title
        this.url = url
        this.thumbnail = thumbnail
    }

}